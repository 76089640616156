import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import FoodMenu from '../components/food_menu';
import FoodProperty from '../components/food_property';
import { pageAction } from '../redux_files/actions/page_actions';
// import { getUserName } from '../utils/common';
// import { checkUserIsAuthAction } from '../redux_files/actions/users_actions';

// import { BarCodeScanner } from './barcode_scanner';

const Home = () => {
  const dispatch = useDispatch();
  // const loggedInUserDetails = useSelector(state => state?.usersReducer?.loggedInUserDetails);
  // const userName = getUserName(loggedInUserDetails);
  useEffect(() => {
    document.title = 'Shivay Durga Foods';
    dispatch(pageAction('Shivay Durga Foods'));
  }, [document.title]);
  // useEffect(() => {
  //   if(userName) dispatch(checkUserIsAuthAction());
  // },[]);
  return (
    <div className='container'>
      {/* <BarCodeScanner /> */}
      {/* <div className='text-center auto-margin'>
        <Link to={userName ? '/cart' : '/login'}><div className='primary-button'>Order Now</div></Link>
      </div> */}
      <div className='text-center auto-margin'>
        <Link taget="_blank" to='https://shivaydurgafoods.dotpe.in/store/1/delivery/'><div className='primary-button'>Click Here to Choose Your Own Menu</div></Link>
      </div>
      <br />
      <div className="text-center auto-margin">*<b>Catering and Tiffin Services</b> available at affordable prices.</div>
      <div className="text-center auto-margin">For more details, please call us at <a href="tel:+919996212490">9996212490</a></div>
      <FoodMenu />
      <FoodProperty />  
    </div>
  );
};

export default Home;
