import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { pageAction } from '../redux_files/actions/page_actions';

const Contactus = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    document.title = 'Contact Us';
    dispatch(pageAction('Contact Us'));
  });
  return (
    <>
      <div className='container'>
        <div className='aboutus outer-border pd-20'>
        For Order please call us at <a href='tel:+919996212490'>9996212490</a>
          <br />
         
        </div>
      </div>
    </>
  );
};

export default Contactus;
